<script>
import searchBar from '@components/searchBar'
import PaginatedDataMixin from "@src/mixins/PaginatedDataMixin";
import PaginationRow from "@components/global/PaginationRow";

export default {
  page: {title: 'Επιστημονική βιβλιοθήκη'},
  components: {PaginationRow, searchBar},
  mixins: [PaginatedDataMixin],

  data() {
      return {
          categories: [],
          selectedCategory: -1,
      }
  },
  computed:{

    // we define an "extra params" computed property that stores all the extra parameters
    // that we define for the API request, like category, type etc
    extraParams(){
      let extraParams = {};
      if(this.selectedCategory > 0) extraParams.category = this.selectedCategory;
      return extraParams;
    }
  },

  async created() {
      this.api.url = '/library'; // set initial API url for the mixin!
      await this.fetchLibraryCategories();

      this.getData();
    },

  methods:{

    fetchLibraryCategories(){
        this.$axios.get(`/library/categories`, ).then(result => {
            this.categories = result.data;
        }).catch(e => {
            this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Η φόρτωση των κατηγοριών απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
        });
    },

    filterCategory(){
        this.page = 1;
        this.getData();
    },

    getCategory(id){
        return this.categories.find(x => {return x.id === id}).name;
    },

  }
}
</script>
<style lang="scss">
</style>

<template>
<div id="science-library-layout">
  <searchBar>
        <template v-slot:forms>
            <form class="form-inline">
                <div class="form-group"><label class="sr-only">{{ $t('search') }}</label>
                    <b-form-input v-model="api.filters.search" type="search" class="form-control" :placeholder="$t('searchPlaceholder')" debounce="400"/>
                </div>

                <div class="form-group mx-sm-3">
                    <label for="subCategory-select" class="mr-2">Φιλτράρισμα ανά κατηγορία:</label>
                    <select id="subCategory-select" v-model="selectedCategory" class="custom-select" @change="filterCategory">
                        <option value='-1'>Προβολή όλων</option>
                        <option v-for="item in categories" :key="item.id" :value="item.id"> {{item.parentCategoryId === null ? '' : ' &mdash;'}}  {{ item.name }}</option>
                    </select>
                </div>
            </form>
        </template>
        <template v-slot:buttons>

        </template>
    </searchBar>
  <div class="row">
      <div class="col-md-12">
          <b-card-group deck>
              <b-card  v-for="article in api.rows" :key="article.id" class="shadow-sm mb-2 mt-2" style="min-width:30%;">
                  <b-card-title>{{article.title}}</b-card-title>
                  <b-card-sub-title v-if="categories.length > 0 && article.articleCategoryId">Δημοσιεύτηκε στην κατηγορία: {{getCategory(article.articleCategoryId)}}</b-card-sub-title>

                  <b-button variant="primary" size="xs" class="mt-2" @click="$router.push('/library/' + article.id)">Ανάγνωση άρθρου</b-button>
              </b-card>

          </b-card-group>

        <pagination-row :api="api" @fetchData="getData"></pagination-row>

          <b-card v-if="api.rows.length <= 0 && selectedCategory !== -1">
              <b-card-text>Δεν βρέθηκαν αποτελέσματα.</b-card-text>
          </b-card>
      </div>

  </div>
</div>


</template>
